/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestPaymentItem,
    PhotoRequestPaymentItemFromJSON,
    PhotoRequestPaymentItemFromJSONTyped,
    PhotoRequestPaymentItemToJSON,
} from './PhotoRequestPaymentItem';

/**
 * 
 * @export
 * @interface PhotoRequestPaymentItemsListResponse
 */
export interface PhotoRequestPaymentItemsListResponse {
    /**
     * Списания за платные услуги в заявке
     * @type {Array<PhotoRequestPaymentItem>}
     * @memberof PhotoRequestPaymentItemsListResponse
     */
    items: Array<PhotoRequestPaymentItem>;
}

export function PhotoRequestPaymentItemsListResponseFromJSON(json: any): PhotoRequestPaymentItemsListResponse {
    return PhotoRequestPaymentItemsListResponseFromJSONTyped(json, false);
}

export function PhotoRequestPaymentItemsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestPaymentItemsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(PhotoRequestPaymentItemFromJSON)),
    };
}

export function PhotoRequestPaymentItemsListResponseToJSON(value?: PhotoRequestPaymentItemsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(PhotoRequestPaymentItemToJSON)),
    };
}

