import ky from 'ky';

export * from './auth';
export * from './error';
export * from './frontend-api-erp-photo-studio';

import { API_URL, WMS_API_URL } from '@/config';

import { AuthApi } from './auth';
import {
  BoxesApi as GeneratedBoxesApi,
  BoxTransferApi as GeneratedBoxTransferApi,
  CategoryApi as GeneratedCategoryApi,
  Configuration,
  DefectsApi as GeneratedDefectsApi,
  ErrorContext,
  InvoiceApi as GeneratedInvoiceApi,
  PaymentsApi as GeneratedPaymentsApi,
  PhotoApi as GeneratedPhotoApi,
  PhotoBoxesApi as GeneratedPhotoBoxesApi,
  PhotoModelApi as GeneratedPhotoModelsApi,
  PhotoRequestApi as GeneratedPhotoRequestApi,
  ReferencesApi as GeneratedReferencesApi,
  ReservationApi as GeneratedReservationApi,
  SellersApi as GeneratedSellersApi,
  ServicesApi as GeneratedServicesApi,
  SKUApi as GeneratedSKUApi,
} from './frontend-api-erp-photo-studio';
import { ApiConfiguration } from './kyConfig';
import { wmsBaseApi } from './wmsApi';

export interface WmsWorker {
  id: number;
  firstName: string;
  lastName: string;
  patronymic: string;
  phone: string;
  roleDto: WmsRoleDto;
  dateCreated: string;
  dateDeleted: string | null;
  active: boolean;
}

export interface WmsRoleDto {
  id: number;
  name: string;
  localizedName: string;
  description: string;
  roleGroup: WmsRoleGroup;
}

export interface WmsRoleGroup {
  id: number;
  groupName: string;
}

const api = ky.create(ApiConfiguration);

export const wmsApi = api.extend({
  prefixUrl: WMS_API_URL,
  credentials: 'omit',
});

const configuration = new Configuration({
  fetchApi: api,
  basePath: API_URL,
});

export const categoryApi = new GeneratedCategoryApi(configuration);

export const invoiceApi = new GeneratedInvoiceApi(configuration);

export const defectsApi = new GeneratedDefectsApi(configuration);

export const photoRequestApi = new GeneratedPhotoRequestApi(
  new Configuration({
    basePath: API_URL,
    fetchApi: api.extend({
      timeout: false,
    }),
  }),
);

export const skuApi = new GeneratedSKUApi(configuration);

export const boxesApi = new GeneratedBoxesApi(configuration);

export const photoBoxesApi = new GeneratedPhotoBoxesApi(configuration);

export const photoModelsApi = new GeneratedPhotoModelsApi(configuration);

export const boxTransferApi = new GeneratedBoxTransferApi(configuration);

export const referencesApi = new GeneratedReferencesApi(configuration);

export const sellersApi = new GeneratedSellersApi(configuration);

export const authApi = new AuthApi(wmsBaseApi);

export const photoApi = new GeneratedPhotoApi(configuration);

export const reservationApi = new GeneratedReservationApi(configuration);

export const servicesApi = new GeneratedServicesApi(configuration);

export const paymentsApi = new GeneratedPaymentsApi(configuration);
