/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PhotoService,
    PhotoServiceFromJSON,
    PhotoServiceToJSON,
    PriceRequest,
    PriceRequestFromJSON,
    PriceRequestToJSON,
    Prices,
    PricesFromJSON,
    PricesToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
} from '../models';

export interface GetPhotoServicesListRequest {
    body?: object;
}

export interface SellerGetServicesPricesRequest {
    priceRequest?: PriceRequest;
}

/**
 * 
 */
export class ServicesApi extends runtime.BaseAPI {

    /**
     * Получить список доступных сервисов (услуг фотостудии)
     */
    async getPhotoServicesListRaw(requestParameters: GetPhotoServicesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PhotoService>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photo-services`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhotoServiceFromJSON));
    }

    /**
     * Получить список доступных сервисов (услуг фотостудии)
     */
    async getPhotoServicesList(requestParameters: GetPhotoServicesListRequest = {}, initOverrides?: RequestInit): Promise<Array<PhotoService>> {
        const response = await this.getPhotoServicesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPricesRaw(requestParameters: SellerGetServicesPricesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Prices>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/services/prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceRequestToJSON(requestParameters.priceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PricesFromJSON(jsonValue));
    }

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPrices(requestParameters: SellerGetServicesPricesRequest = {}, initOverrides?: RequestInit): Promise<Prices> {
        const response = await this.sellerGetServicesPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
