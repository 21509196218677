import { useLocalStorage } from '@vueuse/core';
import flagsmith from 'flagsmith';
import { IFlags } from 'flagsmith/types';
import { reactive } from 'vue';

import { useAuth } from '@/features/Auth';
import { keys } from '@/utils/collection';
import { fullName } from '@/utils/name';

enum EFlag {
  complaints = 'complaints',
  useOldTariffs = 'old_payment_rates_switch',
  paymentsPage = 'payments_page',
  useHigherTariff = 'payment_rate_higher',
  newYearTheme = 'new_year_theme',
  photoUpload = 'photo_upload',
  redesignedPhotorequests = 'redesigned_photorequests',
  autoRetouch = 'auto_retouch',
  multiplyRequestCreation = 'multiply_request_creation',
  useRequestsCache = 'use_requests_cache',
}

type TFeatureFlags = Record<FeatureKey, boolean>;
type FeatureKey = keyof typeof EFlag;

interface ICachedFlags {
  api: string;
  flags: IFlags<EFlag>;
}

/*
  if param cacheFlags = true into flagsmith.init(),then flagsmith cached flags into local storage by this key
  docs: https://docs.flagsmith.com/clients/javascript
 */
const CACHE_KEY = 'BULLET_TRAIN_DB';
const DELAY_UPDATE = 300000;

const defaultFlags: TFeatureFlags = {
  newYearTheme: false,
  useRequestsCache: true,
  paymentsPage: false,
  complaints: false,
  photoUpload: false,
  useHigherTariff: true,
  useOldTariffs: true,
  redesignedPhotorequests: false,
  autoRetouch: false,
  multiplyRequestCreation: false,
};

const cachedFlags = useLocalStorage<ICachedFlags | null>(CACHE_KEY, null, {
  serializer: {
    read: JSON.parse,
    write: JSON.stringify,
  },
});

const getFlagState = (flag: EFlag) => {
  if (cachedFlags.value && flag in cachedFlags.value.flags) {
    return cachedFlags.value.flags[flag].enabled;
  } else if (flag in defaultFlags) {
    return defaultFlags[flag];
  }

  return null;
};

const getParsedFlags = () => {
  return Object.fromEntries(
    keys(EFlag).map((key) => [key, getFlagState(EFlag[key])]),
  );
};

const getFeatureFlags = () => {
  if (cachedFlags.value?.flags) {
    return getParsedFlags();
  } else {
    return defaultFlags;
  }
};

export const featureFlags = reactive(getFeatureFlags());

export const updateFlags = () => {
  setInterval(() => {
    const { account } = useAuth();

    if (!account.value?.userInfo) return;

    const id = `[${account.value?.userInfo.id}] ${fullName(
      account.value?.userInfo,
    )}`;

    flagsmith.identify(id, { role: account.value?.userInfo.role });
  }, DELAY_UPDATE);
};

eventManager.on('flagsmith:change', () => {
  Object.assign(featureFlags, getFeatureFlags());
});
