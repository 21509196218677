/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStatus {
    Unspecified = 'UNSPECIFIED',
    Draft = 'DRAFT',
    Created = 'CREATED',
    Confirmed = 'CONFIRMED',
    Refunded = 'REFUNDED',
    Canceled = 'CANCELED'
}

export function PaymentStatusFromJSON(json: any): PaymentStatus {
    return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatus {
    return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
    return value as any;
}

