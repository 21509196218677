/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentStatus,
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';

/**
 * 
 * @export
 * @interface PhotoRequestPaymentItem
 */
export interface PhotoRequestPaymentItem {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    paymentId: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    paymentItemId: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    photoServiceId: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    price: number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PhotoRequestPaymentItem
     */
    paymentStatus: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    photoRequestItemId: number;
    /**
     * timestamp
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    createdAt: number;
    /**
     * timestamp
     * @type {number}
     * @memberof PhotoRequestPaymentItem
     */
    updatedAt: number;
}

export function PhotoRequestPaymentItemFromJSON(json: any): PhotoRequestPaymentItem {
    return PhotoRequestPaymentItemFromJSONTyped(json, false);
}

export function PhotoRequestPaymentItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestPaymentItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentId': json['paymentId'],
        'paymentItemId': json['paymentItemId'],
        'photoServiceId': json['photoServiceId'],
        'quantity': json['quantity'],
        'price': json['price'],
        'paymentStatus': PaymentStatusFromJSON(json['paymentStatus']),
        'photoRequestItemId': json['photoRequestItemId'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function PhotoRequestPaymentItemToJSON(value?: PhotoRequestPaymentItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentId': value.paymentId,
        'paymentItemId': value.paymentItemId,
        'photoServiceId': value.photoServiceId,
        'quantity': value.quantity,
        'price': value.price,
        'paymentStatus': PaymentStatusToJSON(value.paymentStatus),
        'photoRequestItemId': value.photoRequestItemId,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

