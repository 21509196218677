/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PhotoRequestPaymentItemsListResponse,
    PhotoRequestPaymentItemsListResponseFromJSON,
    PhotoRequestPaymentItemsListResponseToJSON,
    RefundPhotoRequestPaymentsRequest,
    RefundPhotoRequestPaymentsRequestFromJSON,
    RefundPhotoRequestPaymentsRequestToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
} from '../models';

export interface PhotoRequestPaymentsCreateRequest {
    photoRequestID: number;
    requestBody?: Array<object>;
}

export interface PhotoRequestPaymentsListRequest {
    photoRequestID: number;
}

export interface PhotoRequestPaymentsRefundRequest {
    photoRequestID: number;
    refundPhotoRequestPaymentsRequest?: RefundPhotoRequestPaymentsRequest;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Провести списание за платные услуги
     */
    async photoRequestPaymentsCreateRaw(requestParameters: PhotoRequestPaymentsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestPaymentsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/payments/create`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Провести списание за платные услуги
     */
    async photoRequestPaymentsCreate(requestParameters: PhotoRequestPaymentsCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.photoRequestPaymentsCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Получить историю списаний по заявке
     */
    async photoRequestPaymentsListRaw(requestParameters: PhotoRequestPaymentsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestPaymentItemsListResponse>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestPaymentsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/payments`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestPaymentItemsListResponseFromJSON(jsonValue));
    }

    /**
     * Получить историю списаний по заявке
     */
    async photoRequestPaymentsList(requestParameters: PhotoRequestPaymentsListRequest, initOverrides?: RequestInit): Promise<PhotoRequestPaymentItemsListResponse> {
        const response = await this.photoRequestPaymentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Частичный возврат списаний по айтемам заявки
     */
    async photoRequestPaymentsRefundRaw(requestParameters: PhotoRequestPaymentsRefundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestPaymentsRefund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/payments/refund`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundPhotoRequestPaymentsRequestToJSON(requestParameters.refundPhotoRequestPaymentsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Частичный возврат списаний по айтемам заявки
     */
    async photoRequestPaymentsRefund(requestParameters: PhotoRequestPaymentsRefundRequest, initOverrides?: RequestInit): Promise<void> {
        await this.photoRequestPaymentsRefundRaw(requestParameters, initOverrides);
    }

}
